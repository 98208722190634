<script>
    import { Pie } from "vue-chartjs";

    const c = name => {
        const el = document.documentElement;
        return getComputedStyle(el).getPropertyValue(`--v-${name}`);
    };

    export default {
        extends: Pie,
        props: {
            group1: Number,
            group2: Number
        },
        data() {
            return {
                data: {
                    labels: ["Hoitoryhmä 1", "Hoitoryhmä 2"],
                    datasets: [
                        {
                            label: "Jakauma",
                            data: [this.group1, this.group2],
                            backgroundColor: [c("primary-base"), c("accent-base")],
                            hoverBackgroundColor: [
                                c("primary-lighten1"),
                                c("accent-lighten1")
                            ],
                            borderColor: [
                                c("primary-darken1"),
                                c("accent-darken1")
                            ],
                            borderWidth: 1
                        }
                    ]
                },

                options: {
                    cutoutPercentage: 45
                }
            };
        },
        computed: {
            dataValues() {
                return [this.group1, this.group2];
            }
        },

        mounted() {
            this.renderChart(this.data, this.options);
        }
    };
</script>

<style scoped>
</style>