<template>
    <div>
        <h1 class="text-center display-3 py-md-5">Tilastot</h1>

        <template v-if="stats">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-simple-table>
                        <caption class="pa-4 title">
                            Jakauma
                        </caption>
                        <tbody>
                            <tr>
                                <td>Hoitoryhmä 1</td>
                                <td
                                    class="text-right"
                                    data-test="group1-amount"
                                >
                                    {{ stats.distribution[0] }} kpl
                                </td>
                                <td class="text-right">
                                    {{ percentageInGroup(0) }} %
                                </td>
                            </tr>
                            <tr>
                                <td>Hoitoryhmä 2</td>
                                <td
                                    class="text-right"
                                    data-test="group2-amount"
                                >
                                    {{ stats.distribution[1] }} kpl
                                </td>
                                <td class="text-right">
                                    {{ percentageInGroup(1) }} %
                                </td>
                            </tr>
                            <tr v-if="false">
                                <td>Odottaa</td>
                                <td class="text-right">
                                    {{ stats.distribution.wait }} kpl
                                </td>
                                <td class="text-right">—</td>
                            </tr>
                            <tr class="font-weight-bold">
                                <td>Yhteensä</td>
                                <td class="text-right">
                                    {{ totalPatients }}
                                    kpl
                                </td>
                                <td class="text-right">100 %</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <v-simple-table class="mt-6">
                        <caption class="pa-4 title">
                            Kirjaukset
                        </caption>
                        <thead>
                            <tr>
                                <th>Käyttäjänimi</th>
                                <th>Nimi</th>
                                <th class="text-right">Potilaat</th>
                                <th class="text-right">Kieltäytyjät</th>
                                <th class="text-right">Rekry</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="entry in stats.counts"
                                :key="entry.username"
                            >
                                <td>{{ entry.username }}</td>
                                <td>{{ entry.name }}</td>
                                <td class="text-right">
                                    {{ entry.totalPatients }}
                                </td>
                                <td class="text-right">
                                    {{ entry.totalRejectors }}
                                </td>
                                <td class="text-right">
                                    {{
                                        percentage(
                                            entry.totalPatients,
                                            entry.totalRejectors
                                        )
                                    }}
                                    %
                                </td>
                            </tr>

                            <tr class="font-weight-bold">
                                <td colspan="2">Yhteensä</td>
                                <td class="text-right">{{ totalPatients }}</td>
                                <td class="text-right">{{ totalRejectors }}</td>
                                <td class="text-right">
                                    {{
                                        percentage(
                                            totalPatients,
                                            totalRejectors
                                        )
                                    }}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" sm="6">
                    <GraphDistribution
                        :group1="stats.distribution[0]"
                        :group2="stats.distribution[1]"
                    />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import GraphDistribution from "@/graphs/GraphDistribution";
    export default {
        components: { GraphDistribution },
        data() {
            return {
                stats: false
            };
        },
        methods: {
            fetchStats() {
                this.$http
                    .get("/api/stats")
                    .then(response => (this.stats = response.data));
            },

            percentageInGroup(n) {
                const total =
                    (this.stats.distribution[0] || 0) +
                    (this.stats.distribution[1] || 0);
                const inWanted = this.stats.distribution[n];

                if (!inWanted) {
                    return 0;
                }

                return Math.round((inWanted / total) * 1000) / 10;
            },

            percentage(a, b) {
                if (a + b === 0) {
                    return "0";
                }

                return Math.round((a / (a + b)) * 1000) / 10;
            }
        },
        computed: {
            // totalPatients() {
            //     return this.stats.distribution[0] + this.stats.distribution[1];
            // },

            totalPatients() {
                let count = 0;
                for (const user of this.stats.counts) {
                    count += user.totalPatients;
                }

                return count;
            },

            totalRejectors() {
                let count = 0;
                for (const user of this.stats.counts) {
                    count += user.totalRejectors;
                }

                return count;
            }
        },

        mounted() {
            this.fetchStats();
        }
    };
</script>

<style scoped>
</style>